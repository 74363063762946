<template>
    <div>
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="$router.back()"></i>
            发布
        </div>

        <div class="table_card">
            <el-descriptions :column="5" :title="`固件标识：${msg.firmwareModel}`">
                <el-descriptions-item label="版本号">{{msg.version}}</el-descriptions-item>
                <el-descriptions-item label="产品ID">{{msg.pid}}</el-descriptions-item>
                <el-descriptions-item label="创建时间">{{msg.createTime}}</el-descriptions-item>
                <el-descriptions-item label="MD5">
                    <span class="notes" :title="splicMd5()">
                        <span  v-for="(item, index) in urls" :key="index">
                            <span v-if="index > 0">,</span>{{item.md5}}
                        </span>
                    </span>
                </el-descriptions-item>
                <el-descriptions-item label="固件类型">{{$config.getMsgItemUtil($message.firmwareTypeList, msg.firmwareType, 'value', 'text')}}</el-descriptions-item>
                <el-descriptions-item v-for="(item, index) in urls" :key="index" label="下载地址" :labelStyle="{marginTop: index > 0 ? '-20px' : '-10px'}" :contentStyle="{marginTop: index > 0 ? '-20px' : '-10px'}" :span="5">
                    <span v-if="!item.isEdit">
                        <span :id="`url${index}`">{{item.url}}</span>
                        <el-button type="text" style="margin-left: 10px;" :class="`copy${index}`" :data-clipboard-target="`#url${index}`">复制</el-button>
                        <el-button type="text" @click="item.isEdit = !item.isEdit;">编辑</el-button>
                    </span>
                    <span v-else>
                        <el-input size="mini" class="url_input" placeholder="请输入内容" v-model="item.url"/>
                        <el-button type="text" style="margin-left: 10px;" v-if="!item.editLoading" @click="cancel(item, index)">取消</el-button>
                        <el-button type="text" :loading="item.editLoading" @click="modifyUrl(item, index)">确认</el-button>
                    </span>
                </el-descriptions-item>
            </el-descriptions>
            <div class="set_update">
                <el-descriptions class="left_form" :column="3" title="设备端升级设置">
                    <el-descriptions-item label="安装方式">{{$config.getMsgItemUtil($message.installTypeList, msg.triggerType, 'value', 'label')}}</el-descriptions-item>
                    <el-descriptions-item label="触发方式">{{$config.getMsgItemUtil($message.triggerModeList, msg.triggerType, 'value', 'label')}}</el-descriptions-item>
                    <el-descriptions-item label="安装重启">{{msg.reboot ? '是' : '否'}}</el-descriptions-item>
                    <el-descriptions-item label="更新说明" :span="3">
                        <span class="notes" :title="msg.versionDescribe">
                            {{msg.versionDescribe}}
                        </span>
                    </el-descriptions-item>
                </el-descriptions>
                <div class="right_statistics">
                    <div class="statistics_card">
                        <div class="statistics_card_top">{{otaStatisticInfo.totalDeviceCount}}</div>
                        <div class="statistics_card_bottom">总设备数（台）</div>
                    </div>
                    <div class="statistics_card">
                        <div class="statistics_card_top">{{otaStatisticInfo.successCount}}</div>
                        <div class="statistics_card_bottom">已升级设备数（台）</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="from_card">
            <el-radio-group v-model="releaseType" v-if="(!isRelease || isEdit) && !isBeyondTheReleaseTime">
                <el-radio-button :label="item.id" v-for="(item, index) in releaseTypeList" :key="index">{{item.value}}</el-radio-button>
            </el-radio-group>
            <el-radio-group v-model="backup.planPublishType ? backup.planPublishType : backup.publishStatus" v-else>
                <el-radio-button :label="backup.planPublishType ? backup.planPublishType : backup.publishStatus">{{$config.getMsgItemUtil(releaseTypeList, backup.planPublishType ? backup.planPublishType : backup.publishStatus, 'id', 'value')}}</el-radio-button>
            </el-radio-group>

            <div class="title" v-show="releaseType === 1">
                <div class="title_div"></div>
                <span class="title_text">指定发布设置</span>
            </div>
            <el-input
                :disabled="!isEdit && isRelease"
                v-show="releaseType === 1"
                style="margin-top: 10px"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5}"
                :placeholder="
                `请输入设备唯一标识。
格式说明：
多个设备用“,”（逗号）隔开，如：AA:62:94:09:11:10,AA:62:94:09:11:12
连续区间用“起始值~终止值”，如：AA:62:94:09:11:14~AA:62:94:09:11:20`"
                v-model="uniqueId">
            </el-input>
            <el-upload
                v-show="releaseType === 1"
                class="upload-demo"
                :disabled="!isEdit && isRelease"
                :style="{cursor: isEdit ? 'pointer' : 'not-allowed', opacity: isEdit ? '1' : '.8'}"
                :action="$message.deviceUpgradeUrl"
                :headers="{
                    accessToken: $token.getToken().accessToken,
                    refreshToken: $token.getToken().refreshToken,
                    language: language
                }"
                :before-upload="onbeforeunload"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-success="onSuccess"
                :on-error="onError"
                :limit="1"
                :file-list="fileList">
                <el-button :disabled="!isEdit && isRelease" size="mini" icon="el-icon-plus" :loading="fileLoading">本地上传</el-button>
                <div slot="tip" class="el-upload__tip">上传前 <a style="color: rgb(24, 144, 255)" href="https://oss.funlink.cloud/common/hbxpfca/res/20240928/dz7qz8yksq9s/ota_unique_key.xlsx">下载模板</a> ，根据模版要求规范格式</div>
            </el-upload>

            <div class="title" v-show="releaseType === 2">
                <div class="title_div"></div>
                <span class="title_text">灰度发布设置</span>
            </div>
            <el-input-number
                v-show="releaseType === 2"
                :disabled="!isEdit && isRelease"
                v-model="grayAmount"
                :min="0"
                style="margin-top: 10px;"
                :max="10"/>
            <div class="title">
                <div class="title_div"></div>
                <span class="title_text">发布时间</span>
            </div>
            <el-radio-group :disabled="(!isEdit && isRelease) || isBeyondTheReleaseTime" v-model="releaseTimeType">
                <el-radio :label="1">立即发布</el-radio>
                <el-radio :label="2">
                    定时发布
                    <el-date-picker
                        style="margin-left: 10px;"
                        :disabled="releaseTimeType === 1 || (!isEdit && isRelease) || isBeyondTheReleaseTime"
                        v-model="releaseTime"
                        :picker-options="pickerOptions"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择发布时间">
                    </el-date-picker>
                </el-radio>
            </el-radio-group>
            <br>
            <el-button style="margin-top: 20px" :loading="loading" @click="release" type="primary" v-if="!isRelease">发布</el-button>
            <div v-else>
                <el-button style="margin-top: 20px" :loading="removedLoading" @click="banFirmware" v-if="!isEdit">下架</el-button>
                <el-button style="margin-top: 20px;margin-left: 10px;" @click="releaseEdit" v-if="!isEdit || releaseType !== 3">编辑</el-button>

                <el-button style="margin-top: 20px" @click="cancelEdit" v-if="isEdit">取消</el-button>
                <el-button style="margin-top: 20px;margin-left: 10px;" type="primary" :loading="loading"  @click="release" v-if="isEdit">保存</el-button>

                <el-button style="margin-top: 20px;margin-left: 10px;" @click="allRelease" v-if="!isEdit && releaseType === 2">全量发布</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import Clipboard from "clipboard";

export default {
    name: "releaseMsg",
    data() {
        return {
            releaseType: 1,
            grayAmount: 0,
            releaseTypeList: [
                {id: 1, value: '指定发布'},
                {id: 2, value: '灰度发布'},
                {id: 3, value: '全量发布'}
            ],
            backup: {},
            language: localStorage.getItem('language'),
            uniqueId: '',
            releaseTimeType: 1,
            releaseTime: '',
            fileLoading: false,
            loading: false,
            removedLoading: false,
            msg: {},
            urls: [],
            otaStatisticInfo: {
                "totalDeviceCount": "0",
                "successCount": "0",
                "failedCount": "0",
                "upgradingCount": "0",
                "noUpgradingCount": "0"
            },
            fileList: [],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() <= Date.now();
                }
            },
            isRelease: false,
            isEdit: false,
            isBeyondTheReleaseTime: false
        }
    },
    mounted() {
        this.init();
    },
    methods: {

        ...mapActions('maintain', ['getOtaFileDetail', 'otaStatistic', 'queryOTALogPager', 'modifyDownloadUrl', 'publishOTA', 'banOTA', 'getServerTime']),

        init() {
            this.getOtaFileDetail({
                otaFileCode: this.$route.query.code
            }).then(res => {
                if (res.result.planPublishType) {
                    this.isRelease = true;
                } else {
                    this.isRelease = res.result.publishStatus !== 0
                }
                this.msg = res.result;
                this.releaseType = this.isRelease ? (res.result.publishStatus > 0 ? res.result.publishStatus : res.result.planPublishType) : res.result.publishStatus;
                this.uniqueId = this.isRelease ? (res.result.planUniqueKeys ? res.result.planUniqueKeys : res.result.macs) : res.result.macs;
                this.grayAmount = res.result.grayAmount ? res.result.grayAmount : 0;
                this.releaseTimeType = res.result.publishTimeType ? res.result.publishTimeType : 1;
                this.releaseTime = this.releaseTimeType === 1 ? '' : res.result.planPublishTime;

                this.backup = res.result;
                if (this.releaseType === 0) this.releaseType = 1;
                if (this.isRelease) {
                    this.isBeyondTheReleaseTime = this.releaseTime > 0 ? (Date.now() <= new Date().getTime(this.releaseTime)) : this.releaseTimeType === 1;
                } else {
                    this.isBeyondTheReleaseTime = false;
                }
                if (res.result.hasChannel) {
                    let urls = [];
                    res.result.channelList.forEach((item, index) => {
                        urls.push({
                            url: item.downUrl ? item.downUrl : item.fileUrl,
                            url1: item.downUrl ? item.downUrl : item.fileUrl,
                            isEdit: false,
                            editLoading: false,
                            code: item.code,
                            md5: item.md5Sign
                        })
                        new Clipboard('.copy'+index).on('success', (e) => {
                            this.$dialog.showMessage(this.$i18n.t('product.text13'), this.$config.TOAST_SUCCESS);
                        });
                    })
                    this.urls = urls;
                } else {
                    this.urls = [{
                        url: res.result.srcDownUrl ? res.result.srcDownUrl : res.result.srcFileUrl,
                        url1: res.result.srcDownUrl ? res.result.srcDownUrl : res.result.srcFileUrl,
                        isEdit: false,
                        editLoading: false,
                        md5: '--'
                    }]
                    new Clipboard('.copy0').on('success', (e) => {
                        this.$dialog.showMessage(this.$i18n.t('product.text13'), this.$config.TOAST_SUCCESS);
                    });
                }
                console.log(this.isRelease, this.isBeyondTheReleaseTime)
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })

            this.otaStatistic({
                otaFileCode: this.$route.query.code
            }).then(res => {
                this.otaStatisticInfo = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        banFirmware() {
            this.$dialog.showConfirm({
                content: "下架后，终端将无法再升级此版本，重新发布后可下载，请慎重确认是否下架？",
                title: "温馨提示",
                btn1: "已知晓",
                btn2: "取消",
                icon: 2
            }, () => {
                this.banOTA({otaFileCode: this.$route.query.code}).then(res => {
                    this.$dialog.showMessage("下架成功", this.$config.TOAST_SUCCESS);
                    this.init();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {

            })
        },

        splicMd5() {
            let md5 = '';
            this.urls.forEach((item, index) => {
                md5 = md5 + (index > 0 ? ',' : '') + item.md5
            })
            return md5;
        },

        async release() {
            if (this.releaseType === 0) {
                this.$dialog.showMessage("请选择发布类型", this.$config.TOAST_WARNING);
                return
            }
            if (this.releaseType === 2 && this.grayAmount === 0 && !this.grayAmount) {
                this.$dialog.showMessage("灰度发布数量不能为0", this.$config.TOAST_WARNING);
                return;
            }
            if (!this.uniqueId && this.releaseType === 1) {
                this.$dialog.showMessage("请先填写需要发布的设备唯一标识", this.$config.TOAST_WARNING);
                return;
            }
            if (this.releaseTimeType === 2 && this.releaseTime === "") {
                this.$dialog.showMessage("请先选择计划发布时间", this.$config.TOAST_WARNING);
                return;
            }
            let msg = await this.getServerTime();
            if (this.releaseTimeType === 2 && (new Date(this.releaseTime).getTime() - Number(msg.result)) < (10*60*1000)) {
                this.$dialog.showMessage("定时发布的时间必须设置在当前时间后的至少10分钟", this.$config.TOAST_WARNING);
                return;
            }
            this.loading = true;
            let data = {
                otaFileCode: this.$route.query.code,
                publishTimeType: this.releaseTimeType,
                publishType: this.releaseType
            }
            if (this.releaseTimeType === 2) data.planPublishTime = this.releaseTime;
            if (this.releaseType === 1) data.uniqueKeys = this.uniqueId;
            if (this.releaseType === 2) data.grayAmount = this.grayAmount;
            this.publishOTA(data).then(res => {
                this.$dialog.showMessage("发布成功", this.$config.TOAST_SUCCESS);
                this.init();
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => this.loading = false)
        },

        modifyUrl(item, index) {
            this.urls[index].editLoading = true;
            let data = {
                fileUrl: this.urls[index].url,
                otaFileCode: this.$route.query.code
            }
            if (item.code) data.channelCode = item.code
            this.modifyDownloadUrl(data).then(res => {
                this.$dialog.showMessage("修改成功", this.$config.TOAST_SUCCESS);
                this.urls[index].isEdit = false;
                this.urls[index].url1 = this.urls[index].url;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => this.urls[index].editLoading = false)
        },

        cancelEdit() {
            this.releaseTime = this.backup.planPublishTime;
            this.releaseType = this.isRelease ? (this.backup.publishStatus > 0 ? this.backup.publishStatus : this.backup.planPublishType) : this.backup.publishStatus;
            this.uniqueId = this.isRelease ? (this.backup.planUniqueKeys ? this.backup.planUniqueKeys : this.backup.macs) : this.backup.macs;
            this.grayAmount = this.backup.grayAmount ? this.backup.grayAmount : 0;
            this.releaseTimeType = this.backup.publishTimeType ? this.backup.publishTimeType : 1;
            this.releaseTime = this.releaseTimeType === 1 ? '' : this.backup.planPublishTime;

            if (this.releaseTypeList.length === 1) {
                this.isBeyondTheReleaseTime = true;
                this.releaseTypeList = [
                    {id: 1, value: '指定发布'},
                    {id: 2, value: '灰度发布'},
                    {id: 3, value: '全量发布'}
                ];
            }

            this.isEdit = !this.isEdit;
        },

        releaseEdit() {
            this.isEdit = !this.isEdit;
            if (this.releaseType === 3) {
                this.releaseTypeList = [{id: 3, value: '全量发布'}]
            }
        },

        allRelease() {
            this.releaseType = 3;
            this.isEdit = true;
            this.isBeyondTheReleaseTime = false;
            this.releaseTypeList = [{id: 3, value: '全量发布'}]
        },

        cancel(item, index) {
            setTimeout(() => {
                this.urls[index].isEdit = !item.isEdit;
                this.urls[index].url = this.urls[index].url1
            }, 100)
        },

        handleRemove(file, fileList) {
            this.fileList = []
        },

        onbeforeunload(file) {
            this.fileLoading = true;
        },

        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },

        onSuccess(res, file, fileList) {
            this.fileLoading = false;
            console.log(res)
            if (res.success) {
                this.uniqueId = (this.uniqueId ? this.uniqueId : '') + ((this.uniqueId && this.uniqueId !== '') ? ',' : '') + res.result;
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                this.fileList = []
            }
        },

        onError(err, file, fileList) {
            this.fileLoading = false;
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        }
    }
}
</script>

<style scoped>

.table_card {
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 20px 24px;
    box-shadow: 0 0 4px 0 #BCBCBC;
    padding: 20px;
    text-align: left;
}

.title {
    font-size: 18px;
    color: rgb(51, 51, 51);
    font-weight: bold;
}

/deep/ .el-descriptions-item__container {
    align-items: center;
    /*margin-top: -10px;*/
}

.set_update {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.left_form {
    flex: 1;
}

.right_statistics {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100%;
}

.notes {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/deep/ .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 6px;
}

/deep/ .el-descriptions__header {
    margin-bottom: 10px;
}

.statistics_card {
    min-width: 200px;
    height: 100%;
    border: 1px solid #c1c1c1;
    border-radius: 8px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.statistics_card_top {
    color: rgb(0, 0, 0);
    font-size: 36px;
}

.statistics_card_bottom {
    margin-top: 10px;
    color: rgb(0, 0, 0);
    font-size: 14px;
}

.from_card {
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 0 24px 20px 24px;
    box-shadow: 0 0 4px 0 #BCBCBC;
    padding: 20px;
    text-align: left;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
}

.title_div {
    width: 5px;
    height: 18px;
    background-color: rgb(129, 194, 255);
}

.title_text {
    font-size: 16px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    margin-left: 11px;
}

.upload-demo {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/deep/ .el-upload__tip {
    margin: 0 10px!important;
}

/deep/ .el-upload-list__item:first-child {
    margin-top: 0!important;
}

/deep/ .el-icon-close-tip {
    display: none!important;
}

.url_input {
    width: 600px;
}
</style>
