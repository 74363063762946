<template>
    <el-drawer
        :title="type === 0 ? '新增OTA' : '修改OTA'"
        :visible.sync="drawer"
        :size="500"
        direction="rtl"
        append-to-body
        :wrapperClosable="false"
        @open="handleOpen"
        @close="closeDialog">
        <div class="title">基本设置</div>
        <el-form
            :model="form" size="small"
            :rules="rules"
            ref="ruleForm"
            label-position="top"
            class="ota_form">

            <el-form-item prop="pid">
                <span slot="label">
                    产品名称/昵称
                    <el-popover
                        placement="bottom"
                        title=""
                        width="200"
                        trigger="hover">
                        <div>
                            <p>产品名称：在IoT平台已创建产品的名称，您可选择产品名称。</p>
                            <p>产品昵称：没有在平台创建产品，但需使用OTA功能，您可输入/选择产品昵称。</p>
                        </div>
                        <i
                            :class="productIcon"
                            @mouseover="productIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="productIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-select
                    style="width: 100%;"
                    :remote-method="remoteMethod"
                    @change="selectPid"
                    allow-create
                    :loading="loadingProduct"
                    v-model="form.pid"
                    remote
                    ref="selectProduct"
                    filterable
                    :disabled="type === 1"
                    placeholder="请选择产品名称/昵称">
                    <el-option-group
                        v-for="group in productList"
                        :key="group.label"
                        :label="group.label">
                        <el-option
                            v-for="item in group.options"
                            :key="item.value"
                            :label="item.label + `(PID：${item.value})`"
                            :value="item.value">
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>


            <el-form-item prop="firmwareModel">
                <span slot="label">
                    固件/APP标识
                    <el-popover
                        placement="bottom"
                        title=""
                        width="200"
                        trigger="hover">
                        <div>
                            固件标识的一致性验证，是确保升级对象是否在OTA升级范围内的关键条件之一。
                        </div>
                        <i
                            :class="firmwareIcon"
                            @mouseover="firmwareIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="firmwareIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-input :disabled="type === 1" style="width: 100%;" v-model="form.firmwareModel" placeholder="输入择固件/APP标识"/>
            </el-form-item>

            <el-form-item class="is-required" :error="firmwareErr">
                <span slot="label">
                    升级包
                    <el-popover
                        placement="bottom"
                        title=""
                        width="200"
                        trigger="hover">
                        <div>
                            请参照示例进行升级包打包，否则有可能导致升级失败
                        </div>
                        <i
                            :class="uploadIcon"
                            @mouseover="uploadIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="uploadIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>

                <div class="upload-body"
                     :style="{cursor: type === 0 ? 'pointer' : 'not-allowed', opacity: type === 0 ? '1' : '.8'}"
                     :loading="fileLoading">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        :disabled="type === 1"
                        :action="$message.uploadImgUrl"
                        :data="{
                           bucket: 'ota',
                           holdName: 'N' // 保持上传文件名不变
                        }"
                        :headers="{
                            accessToken: $token.getToken().accessToken,
                            refreshToken: $token.getToken().refreshToken,
                            language: language
                        }"
                        drag
                        v-loading="fileLoading"
                        :on-success="onSuccess"
                        :on-error="onError"
                        :before-upload="onbeforeunload"
                        :limit="1"
                        :show-file-list="false"
                        accept=".zip">
                        <div v-if="!file.name" :loading="fileLoading">
                            <i class="el-icon-upload" style="margin-top: 20px;font-size: 40px;"></i>
                            <div class="el-upload__text">{{$i18n.t('empower.text65')}}</div>
                            <div class="el-upload__subtitle">升级包仅支持.zip格式，大小不超过8GByte</div>
                        </div>
                        <div class="upload-msg"
                             :style="{cursor: type === 0 ? 'pointer' : 'not-allowed', opacity: type === 0 ? '1' : '.8'}" v-else>
                            <span>{{ file.name }}</span>
                            <el-button type="text" style="padding: 0px;"
                                       icon="el-icon-delete" @click.stop="delBtn()"></el-button>
                        </div>
                    </el-upload>
                    <div class="upload_title">
                        注意：上传升级包时，请确保包含版本号等必要信息。详情查看
                        <a style="color: rgb(24, 144, 255)" href="https://oss.funlink.cloud/common/hbxpfca/res/20240928/dz7rpgjfzeo0/ota_pack_2_1_21_33.zip">升级包示例</a>
<!--                        <el-button type="text"-->
<!--                                 style="font-size: 12px;margin-left: 10px;">升级包示例-->
<!--                        </el-button>-->
                    </div>
                </div>
            </el-form-item>

            <div class="title" style="margin-top: 36px;margin-bottom: 17px;">设备端升级设置</div>
            <el-form-item prop="triggerType">
                <span slot="label">
                    触发方式
                    <el-popover
                        placement="bottom"
                        title=""
                        width="200"
                        trigger="hover">
                        <div>
                            1、自检升级：在设备的设置时间点（如每天凌晨三点）自动执行检测，符合条件即刻升级。<br>
                            2、检测升级：用户通过设备的人机交互界面（如触摸屏）触发设备检测，符合条件即刻升级。<br>
                            3、APP触发升级：用户在小蜂连连APP操作，设备一旦在线触发设备检测，符合条件即刻升级。<br>
                            4、服务器触发：新固件发布后，服务器推送更新指令至设备，设备一旦在线即自动执行升级。<br>
                        </div>
                        <i
                            :class="triggerModeIcon"
                            @mouseover="triggerModeIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="triggerModeIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-select style="width: 100%;" v-model="form.triggerType" @change="triggerChange" :disabled="form.pid === ''" placeholder="请选择触发方式">
                    <el-option
                        v-for="item in triggerModeList"
                        :disabled="item.isDisabled"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="downloadType">
                <span slot="label">
                    下载方式
                    <el-popover
                        placement="bottom"
                        title=""
                        width="260"
                        trigger="hover">
                        <div>
                            前台下载：固件下载，用户可见。<br>
                            静默下载：固件下载，用户不可见。<br>
                        </div>
                        <i
                            :class="downloadTypeIcon"
                            @mouseover="downloadTypeIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="downloadTypeIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-select style="width: 100%;" v-model="form.downloadType" @change="downloadChange" :disabled="form.triggerType ===''" placeholder="请选择下载方式">
                    <el-option
                        v-for="item in downloadTypeList"
                        :disabled="item.isDisabled"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="installType">
                <span slot="label">
                    安装方式
                    <el-popover
                        placement="bottom"
                        title=""
                        width="260"
                        trigger="hover">
                        <div>
                            强制安装：必须安装，不支持取消下载安装。<br>
                            非强制安装：支持用户取消下载安装。<br>
                        </div>
                        <i
                            :class="installTypeIcon"
                            @mouseover="installTypeIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="installTypeIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-select style="width: 100%;" v-model="form.installType" :disabled="form.downloadType === ''" placeholder="请选择安装方式">
                    <el-option
                        v-for="item in installTypeList"
                        :disabled="item.isDisabled"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>


            <el-form-item label="安装重启" prop="reboot">
                <el-radio-group v-model="form.reboot">
                    <el-radio :label="item.value" :key="index" v-for="(item, index) in rebootList">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="擦除分区">
                <el-checkbox-group v-model="partitions">
                    <el-checkbox :label="item.value" :key="index" v-for="(item, index) in partitionList">{{item.label}}</el-checkbox>
                </el-checkbox-group>
                <el-button type="text" @click="addPartition">新增分区</el-button>
            </el-form-item>

            <el-form-item label="更新说明" prop="versionDescribe">
                <el-input
                    type="textarea"
                    :rows="4"
                    :maxlength="200"
                    placeholder="请输入更新说明"
                    v-model="form.versionDescribe">
                </el-input>
                <div style="position: absolute;right: 0">{{form.versionDescribe.length}}/200</div>
            </el-form-item>

        </el-form>

        <!-- 弹窗底部按钮 start -->
        <div class="demo-drawer__footer">
            <el-button @click="closeDialog">取消</el-button>
            <el-button type="primary" @click="submit" :loading="loading">{{type === 0 ? '新增' : '修改'}}</el-button>
        </div>
        <!-- 弹窗底部按钮 end -->
    </el-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "addOTADrawer",

    computed: {
        ...mapState('maintain', ['addOTADrawerVisible', 'addOTADrawerType', 'firmwareMsg'])
    },

    watch: {

        addOTADrawerVisible: {
            handler(newVal) {
                this.drawer = newVal;
            },
            deep: true
        },

        addOTADrawerType: {
            handler(newVal) {
                this.type = newVal;
            }
        },

        firmwareMsg: {
            handler(newVal) {
                this.form = {
                    otaFileCode: newVal.code,
                    pid: newVal.pid,
                    firmwareModel: newVal.firmwareModel,
                    filePath: newVal.srcFilePath,
                    fileUrl: newVal.srcFileUrl,
                    productType: newVal.productType,
                    triggerType: newVal.triggerType ? newVal.triggerType : '',
                    downloadType: newVal.downloadType ? newVal.downloadType : '',
                    installType: newVal.installType ? newVal.installType : '',
                    reboot: newVal.reboot ? "1" : "2",
                    versionDescribe: newVal.versionDescribe ? newVal.versionDescribe : ''
                }
                this.productList = {
                    label: newVal.productType === 1 ? '产品名称' : '产品昵称',
                    options: [
                        {
                            label: newVal.productName,
                            value: newVal.pid
                        }
                    ]
                }
                console.log(this.productList)
                let partitions = [];
                if (newVal.wipeData) partitions.push(1)
                if (newVal.wipeMedia) partitions.push(2)
                newVal.wipeArea && newVal.wipeArea !== "" && JSON.parse(newVal.wipeArea).forEach(item => {
                    this.partitionList.push({label: item.n, value: item.k})
                    if (item.v) partitions.push(item.k)
                })
                this.partitions = partitions;
                if (!newVal.srcFilePath) return
                let filePathList = newVal.srcFilePath.split("/")
                this.file.name = filePathList[filePathList.length - 1];
            }
        }
    },

    data() {
        return {
            type: 0,
            drawer: false,
            loading: false,
            productIcon: "empower_question_alicon iconfont_al icon-yiwen",
            firmwareIcon: "empower_question_alicon iconfont_al icon-yiwen",
            uploadIcon: "empower_question_alicon iconfont_al icon-yiwen",
            triggerModeIcon: "empower_question_alicon iconfont_al icon-yiwen",
            downloadTypeIcon: "empower_question_alicon iconfont_al icon-yiwen",
            installTypeIcon: "empower_question_alicon iconfont_al icon-yiwen",
            fileLoading: false,
            loadingProduct: false,
            productName: '',
            language: localStorage.getItem('language'),
            form: {
                otaFileCode: '',
                pid: "",
                firmwareModel: "",
                filePath: "",
                fileUrl: "",
                productType: 1,
                triggerType: "",
                downloadType: "",
                installType: "",
                reboot: "1",
                versionDescribe: ""
            },
            partitions: [],
            file: {},
            firmwareErr: "",
            rules: {
                pid: {required: true, message: '请选择产品名称/昵称', trigger: 'change'},
                firmwareModel: {required: true, message: '请输入固件/APP标识', trigger: 'change'},
                triggerType: {required: true, message: '请选择触发方式', trigger: 'change'},
                downloadType: {required: true, message: '请选择下载方式', trigger: 'change'},
                installType: {required: true, message: '请选择安装方式', trigger: 'change'},
                reboot: {required: true, message: '请选择是否安装重启', trigger: 'change'},
                versionDescribe: {required: true, message: '请输入更新说明'},
            },
            installTypeList: this.$message.installTypeList,
            triggerModeList: this.$message.triggerModeList,
            productList: [
                {
                    label: '产品名称',
                    options: []
                },
                {
                    label: '产品昵称',
                    options: []
                }
            ],
            downloadTypeList: [
                {label: '前台下载', value: 0, isDisabled: false},
                {label: '静默下载', value: 1, isDisabled: false},
            ],
            rebootList: [
                {label: '是', value: '1'},
                {label: '否', value: '2'},
            ],
            partitionList: [
                {label: '数据分区', value: 1},
                {label: '媒体分区', value: 2},
            ]
        }
    },

    methods: {

        ...mapActions('maintain', ['closeAddOTADrawer', 'queryProductAndAliasSimpleList', 'addProductalias', 'importFirmwareFile', 'modifyFirmwareFile']),

        selectPid(event) {
            console.log(event)
            if (this.productName === event) {
                this.$dialog.showConfirm({
                    content: "未找到该产品名称/昵称，是否创建为新的产品昵称？",
                    title: "温馨提示",
                    btn1: "是",
                    btn2: "否",
                    icon: 2
                }, () => {
                    this.addProductalias({
                        name: event
                    }).then(res => {
                        if (this.productList[1].options) {
                            this.productList[1].options.push({
                                label: event,
                                value: res.result
                            })
                        } else {
                            this.productList[1] ={
                                label: '产品昵称',
                                options: [{
                                    label: event,
                                    value: res.result
                                }]
                            }
                        }
                        this.form.pid = res.result;
                        this.form.productType = 2;
                        this.triggerModeList.forEach(item => {
                            item.isDisabled = !(item.value === 0 || item.value === 3);
                        })
                        this.form.triggerType = "";
                        this.form.downloadType = "";
                        this.form.installType = "";
                        this.$refs.selectProduct.blur();

                    })
                }, () => {
                    this.form.pid = ""
                    this.$refs.selectProduct.blur();
                })
            } else {
                this.form.pid = event;
                let isIOTProduct = false;
                this.productList[0].options.forEach(item => {
                    if (item.value === event) {
                        isIOTProduct = true;
                    }
                });
                this.form.productType = isIOTProduct ? 1 : 2;
                this.form.productType === 1 && this.triggerModeList.forEach(item => {item.isDisabled = false;})
                this.form.productType === 2 && this.triggerModeList.forEach(item => {
                    item.isDisabled = !(item.value === 0 || item.value === 3);
                })
                this.form.triggerType = "";
                this.form.downloadType = "";
                this.form.installType = "";
            }
        },

        remoteMethod(event) {
            this.productName = event;
            if (event === '') {
                this.productList = []
                return;
            }
            this.loadingProduct = true;
            this.queryProductAndAliasSimpleList({
                productName: event
            }).then(res => {
                if (res.code === '0') {
                    let productAliasList = [];
                    let productList = [];
                    res.result.productAliasList.forEach(item => {
                        productAliasList.push({
                            label: item.name,
                            value: item.pid
                        })
                    })
                    res.result.productList.forEach(item => {
                        productList.push({
                            label: item.name,
                            value: item.pid
                        })
                    })
                    this.productList[0] = productList.length > 0 ? {
                        label: '产品名称',
                        options: productList
                    } : {}
                    this.productList[1] = productAliasList.length > 0 ? {
                        label: '产品昵称',
                        options: productAliasList
                    } : {}
                }
            }).finally(() => this.loadingProduct = false)
        },

        addPartition() {
            this.$prompt('请输入分区名称', '新增分区', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^(?!\s*$).{1,20}$/,
                inputErrorMessage: '分区名称大于0小于等于20字符'
            }).then(({ value }) => {
                this.partitionList.push({
                    value: this.partitionList.length + 1,
                    label: value
                })
                this.$message({
                    type: 'success',
                    message: '新增成功'
                });
            }).catch(() => {});
        },

        delBtn() {
            this.file = {}
            this.form.filePath = '';
            this.form.fileUrl = '';
            this.$refs.upload.clearFiles();
        },

        triggerChange(event) {
            this.form.downloadType = "";
            this.form.installType = "";
            // 产品
            (this.form.productType === 1 && event === 0) && this.downloadTypeList.forEach(item => {item.isDisabled = false;});
            (this.form.productType === 1 && event === 1) && this.downloadTypeList.forEach(item => {
                item.isDisabled = item.value !== 1;
            });
            (this.form.productType === 1 && event === 2) && this.downloadTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 1 && event === 3) && this.downloadTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });

            // 昵称
            (this.form.productType === 2 && event === 0) && this.downloadTypeList.forEach(item => {item.isDisabled = false;});
            (this.form.productType === 2 && event === 3) && this.downloadTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
        },

        downloadChange(event) {
            this.form.installType = "";
            //产品
            (this.form.productType === 1 && this.form.triggerType === 0 && event === 0) && this.installTypeList.forEach(item => {item.isDisabled = false;});
            (this.form.productType === 1 && this.form.triggerType === 0 && event === 1) && this.installTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 1 && this.form.triggerType === 1 && event === 1) && this.installTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 1 && this.form.triggerType === 2 && event === 0) && this.installTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 1 && this.form.triggerType === 3 && event === 0) && this.installTypeList.forEach(item => {item.isDisabled = false;});

            // 昵称
            (this.form.productType === 2 && this.form.triggerType === 0 && event === 0) && this.installTypeList.forEach(item => {item.isDisabled = false;});
            (this.form.productType === 2 && this.form.triggerType === 0 && event === 1) && this.installTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 2 && this.form.triggerType === 3 && event === 0) && this.installTypeList.forEach(item => {item.isDisabled = false;});
            console.log(this.form.productType, this.form.triggerType, event, this.installTypeList)
        },

        onbeforeunload(file) {
            console.log(file, file.size)
            this.fileLoading = true;
            if (file.size > (8*1024*1024*1024)) {
                this.firmwareErr = "文件大小不能超过不超过8GByte";
                this.fileLoading = false;
                return false;
            }
            return true;
        },

        onSuccess(response, file, fileList) {
            this.fileLoading = false;

            console.log(response)

            if (response.success) {
                this.file = file;
                this.file.name = file.name;
                this.form.filePath = response.result.fileList[0].filePath;
                this.form.fileUrl = response.result.fileList[0].url;
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
            }
        },

        onError(err, file, fileList) {
            this.fileLoading = false;
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        },

        handleOpen() {

        },

        closeDialog() {
            this.closeAddOTADrawer();
            this.$refs.ruleForm.resetFields();
            this.form = {
                otaFileCode: "",
                pid: "",
                firmwareModel: "",
                filePath: "",
                fileUrl: "",
                productType: 1,
                triggerType: "",
                downloadType: "",
                installType: "",
                reboot: "1",
                versionDescribe: ""
            }
            this.partitions = []
            this.partitionList = [
                {label: '数据分区', value: 1},
                {label: '媒体分区', value: 2},
            ]
            this.file = {};
            this.firmwareErr = ""
        },

        submit() {
            if (!this.form.fileUrl) {
                this.firmwareErr = "请上传升级包"
                return;
            }
            this.loading = true;
            this.firmwareErr = ""
            let wipeArea = [];
            let wipeData = "false";
            let wipeMedia = "false";
            this.partitionList.forEach((item, index) => {
                if (index > 1) {
                    wipeArea.push({
                        k: item.value,
                        v: this.partitions.includes(item.value),
                        n: item.label
                    })
                }
            })
            if (this.partitions.length > 0) {
                this.partitions.forEach(item => {
                    if (item === 1) {
                        wipeData = "true";
                    } else if (item === 2) {
                        wipeMedia = "true";
                    }
                })
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (this.type === 0) {
                        this.importFirmwareFile({
                            downloadType: this.form.downloadType,
                            filePath: this.form.filePath,
                            fileUrl: this.form.fileUrl,
                            firmwareModel: this.form.firmwareModel,
                            installType: this.form.installType,
                            pid: this.form.pid,
                            reboot: this.form.reboot === '1' ? 'true' : 'false',
                            productType: this.form.productType,
                            triggerType: this.form.triggerType,
                            versionDescribe: this.form.versionDescribe,
                            wipeArea: JSON.stringify(wipeArea),
                            wipeData,
                            wipeMedia
                        }).then(res => {
                            this.$dialog.showMessage("添加成功", this.$config.TOAST_SUCCESS);
                            this.$emit('getMsg');
                            this.closeDialog();
                        }, err => {
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                    if (this.type === 1) {
                        this.modifyFirmwareFile({
                            downloadType: this.form.downloadType,
                            installType: this.form.installType,
                            otaFileCode: this.form.otaFileCode,
                            productType: this.form.productType,
                            reboot: this.form.reboot === '1' ? 'true' : 'false',
                            triggerType: this.form.triggerType,
                            versionDescribe: this.form.versionDescribe,
                            wipeArea: JSON.stringify(wipeArea),
                            wipeData,
                            wipeMedia
                        }).then(res => {
                            this.$dialog.showMessage("修改成功", this.$config.TOAST_SUCCESS);
                            this.$emit('getMsg');
                            this.closeDialog();
                        }, err => {
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                }
            })
        }

    }

}
</script>

<style scoped>
/deep/ .el-drawer__header {
    font-weight: bold !important;
    text-align: left;
    color: black !important;
    padding: 10px 20px;
    margin-bottom: 0px;
    border-bottom: 1px solid #ebecf0;
}

/deep/ .el-drawer__body {
    padding: 0 20px;
}

/deep/ .el-divider--horizontal {
    margin: 0;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

/deep/ .demo-drawer__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px 20px;
    width: 500px;
    text-align: left;
    background-color: white;
    border-top: 1px solid #ebecf0;
}

.title {
    font-size: 14px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    margin-top: 17px;
}

.upload_title {
    font-size: 12px;
    color: rgb(127, 127, 127);
    margin-top: -26px;
}

.ota_form {
    margin-bottom: 100px;
    margin-top: 14px;
}

.empower_question_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    top: 1px;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.empower_question_alicon {
    color: #3296FA;
    font-size: 16px;
    position: relative;
    top: 1px;
    cursor: pointer;
}

.upload-body {
    /*position: relative;*/
    /*left: 0;*/
    /*right: 0;*/
    /*top: 0;*/
    height: 150px;
    border-radius: 4px;
}

.upload-demo {
    /*position: absolute;*/
    /*top: 10px;*/
    /*bottom: 10px;*/
    /*left: 10px;*/
    /*right: 10px;*/
}

.upload-msg {
    margin-top: 50px;
    font-size: 20px;
    color: #3296FA;
}

/deep/ .el-upload-dragger, /deep/ .el-upload {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
}

.el-upload__text {
    line-height: 5px;
    margin-top: -10px;
}

.el-upload__subtitle {
    font-size: 12px;
    line-height: 30px;
}
</style>
